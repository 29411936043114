<template>
	<div class="container">
		<top-header></top-header>
		<div class="cloudData width-1300">
			<div class="pc-user">
				<div class="label">个人中心</div>
				<div class="top-option">
					<div class="item" @click="gourl('/user')">
						<span>基本设置</span>
					</div>
					<div class="item active" @click="gourl('/cloudData')">
						<span>点云数据</span>
					</div>
					<div class="item" @click="gourl('/messageList')">
						<span>我的留言</span>
					</div>
				</div>
				<div class="mo-label">点云数据</div>
				<div class="company">
					<div class="item" v-for="(item,index) in companyList" :key="index"
						:class="item.id == value && value ? 'active' : ''" @click="topClick('company',item.id)">
						<span>{{item.label}}</span>
					</div>
				</div>
				<div class="mo-company">
					<p>选择公司</p>
					<el-select v-model="value" placeholder="请选择">
						<el-option v-for="item in companyList" :key="item.id" :label="item.label" :value="item.id">
						</el-option>
					</el-select>
				</div>
				<div class="mine">
					<div class="item" v-for="(item,index) in mineList" :key="index"
						:class="item.id == value2 && value2 ? 'active' : ''" @click="topClick('mine',item.id)">
						<span>{{item.label}}</span>
					</div>
				</div>
				<div class="mo-mine">
					<p>选择矿山</p>
					<el-select v-model="value2" placeholder="请选择">
						<el-option v-for="item in mineList" :key="item.id" :label="item.label" :value="item.id">
						</el-option>
					</el-select>
				</div>
				<div class="mill">
					<div class="item" v-for="(item,index) in millList" :key="index"
						:class="item.id == value3 && value3 ? 'active' : ''" @click="topClick('mill',item.id)">
						<span>{{item.label}}</span>
					</div>
				</div>
				<div class="mo-mill">
					<p>选择磨机</p>
					<el-select v-model="value3" placeholder="请选择">
						<el-option v-for="item in millList" :key="item.id" :label="item.label" :value="item.id">
						</el-option>
					</el-select>
				</div>
				<div class="liner">
					<div class="item" v-for="(item,index) in linerList" :key="index"
						:class="item.id == value4 && value4 ? 'active' : ''" @click="topClick('liner',item.id)">
						<span>{{item.label}}</span>
					</div>
				</div>
				<div class="mo-liner">
					<p>选择衬板安装类型</p>
					<el-select v-model="value4" placeholder="请选择">
						<el-option v-for="item in linerList" :key="item.id" :label="item.label" :value="item.id">
						</el-option>
					</el-select>
				</div>
				<div class="test-se">
					<div class="test">
						<h4>点云数据列表</h4>
					</div>
					<div class="search">
						<input type="text" placeholder="">
						<button><i class="iconfont iconsousuo"></i></button>
					</div>
				</div>
				<div class="table">
					<el-table :data="tableData" stripe style="width: 100%" border :header-cell-style="headerCellstyle">
						<el-table-column prop="id" label="ID" width="70" align="center">
						</el-table-column>
						<el-table-column prop="name" label="名称" width="150" align="center">
						</el-table-column>
						<el-table-column prop="date" label="安装时间" width="180" align="center">
						</el-table-column>
						<el-table-column prop="date" label="扫描时间" width="180" align="center">
						</el-table-column>
						<el-table-column label="操作" align="center">
							<template slot-scope="scope">
								<el-button size="mini" type="success" @click="handleEdit(scope.$index, scope.row)">查看
								</el-button>
								<el-button size="mini" type="primary" @click="handleDelete(scope.$index, scope.row)">修改
								</el-button>
							</template>
						</el-table-column>
					</el-table>
				</div>
				<div class="page">
					<el-pagination layout="prev, pager, next" :total="100" :page-size="num" :pager-count="5"
						@current-change="handleCurrentChange" small background>
					</el-pagination>
				</div>
			</div>
		</div>
		<bottom-footer></bottom-footer>
	</div>
</template>

<script>
	import topHeader from '../../components/header.vue'
	import bottomFooter from '../../components/footer.vue'
	export default {
		components: {
			topHeader,
			bottomFooter,
		},
		data() {
			return {
				page: 1,
				num: 10,
				companyList: [{
					id: 1,
					label: '北京旺企业'
				}, {
					id: 2,
					label: '阿里巴巴'
				}, {
					id: 3,
					label: '江西耐普矿机股份有限公司'
				}, {
					id: 4,
					label: '江西耐普矿机股份有限公司'
				}, {
					id: 5,
					label: '江西耐普矿机股份有限公司'
				}],
				value: 1,
				mineList: [{
					id: 1,
					label: '大矿山'
				}, {
					id: 2,
					label: '大矿山'
				}, {
					id: 3,
					label: '大矿山'
				}, {
					id: 4,
					label: '大矿山'
				}, {
					id: 5,
					label: '大矿山'
				}],
				value2: 1,
				millList: [{
					id: 1,
					label: 'XX磨机'
				}, {
					id: 2,
					label: 'XX磨机'
				}, {
					id: 3,
					label: 'XX磨机'
				}, {
					id: 4,
					label: 'XX磨机'
				}, {
					id: 5,
					label: 'XX磨机'
				}],
				value3: 1,
				linerList: [{
					id: 1,
					label: '进料安装'
				}, {
					id: 2,
					label: '进料安装'
				}, {
					id: 3,
					label: '进料安装'
				}],
				value4: 1,
				tableData: [{
					id: 1,
					date: '2016-05-02',
					name: '金属衬板28日',
					address: '上海市普陀区金沙江路 1518 弄'
				}, {
					id: 1,
					date: '2016-05-04',
					name: '金属衬板28日',
					address: '上海市普陀区金沙江路 1517 弄'
				}, {
					id: 1,
					date: '2016-05-01',
					name: '金属衬板28日',
					address: '上海市普陀区金沙江路 1519 弄'
				}, {
					id: 1,
					date: '2016-05-03',
					name: '金属衬板28日',
					address: '上海市普陀区金沙江路 1516 弄'
				}]
			}
		},
		created() {

		},
		methods: {
			gourl(url) {
				this.$router.push(url)
			},
			handleEdit(index, row) {
				//console.log(index, row);
				this.$router.push({
					query: {
						id: row.id
					},
					name: 'cloudDatainfo'
				})
			},
			handleDelete(index, row) {
				//console.log(index, row);
				this.$router.push({
					query: {
						id: row.id
					},
					name: 'databaseupdate'
				})
			},
			//设置表头背景色
			headerCellstyle() {
				return 'backgroundColor:#FAFAFA'
			},
			handleCurrentChange(val) {
				//console.log(`当前页: ${val}`);
			},
			topClick(type, id) {
				// console.log(type,id);
				if (type == 'company') {
					if (this.value == id) {
						this.value = '';
					} else {
						this.value = id;
					}
				} else if (type == 'mine') {
					if (this.value2 == id) {
						this.value2 = '';
					} else {
						this.value2 = id;
					}
				} else if (type == 'mill') {
					if (this.value3 == id) {
						this.value3 = '';
					} else {
						this.value3 = id;
					}
				} else if (type == 'liner') {
					if (this.value4 == id) {
						this.value4 = '';
					} else {
						this.value4 = id;
					}
				}
			},
		}
	}
</script>

<style lang="scss">
	.btn-prev,
	.btn-next {
		border: 1px solid !important;
		width: 40px !important;
		height: 40px !important;
		border-radius: 50% !important;
	}

	.el-pager {
		vertical-align: middle;
		margin-top: 10px;
	}

	.el-pagination.is-background .el-pager li {
		background-color: #fff;
		font-size: 14px;
	}

	.el-pagination.is-background .el-pager li:not(.disabled).active {
		background-color: #fff;
		color: #409EFF;
		text-decoration: underline;
	}

	.cell {
		.el-button {
			background-color: rgba($color: #fff, $alpha: 0);
			border-color: #215FB0;
			color: #215FB0;
		}
	}

	.has-gutter {
		th {
			color: #215FB0;
		}
	}

	.cloudData {
		margin-top: 85px;
		min-height: 803px;
		position: relative;

		@media screen and(min-width:320px) and (max-width:767px) {
			padding: 0 0;
			margin-top: 85px;
			min-height: 100vh;
		}

		.table {
			margin-top: 30px;
		}

		.page {
			text-align: center;
			margin-top: 30px;
		}

		.test-se {
			.test {
				width: 50%;
				margin-right: 10%;
				display: inline-block;
				vertical-align: middle;
				color: #0064C8;
				text-align: right;
				@media screen and(min-width:320px) and (max-width:767px) {
					display: none;
				}
			}

			.search {
				display: inline-block;
				vertical-align: middle;
				width: 40%;
				@media screen and(min-width:320px) and (max-width:767px) {
					width: 100%;
					margin: 20px 0px;
				}

				input {
					border-top: 1px solid #888;
					border-bottom: 1px solid #888;
					border-left: 1px solid #888;
					border-top-left-radius: 40px;
					border-bottom-left-radius: 40px;
					padding: 0 15px;
					box-sizing: border-box;
					border-right: 0;
					width: 89%;
					height: 40px;
					vertical-align: middle;
					outline: none;
					transition: border-color .3s;

					@media screen and(min-width:320px) and (max-width:767px) {
						width: 79%;
					}
				}

				button {
					border: 0;
					border-top: 1px solid #888;
					border-bottom: 1px solid #888;
					border-right: 1px solid #888;
					vertical-align: middle;
					cursor: pointer;
					background-color: #fff;
					width: 10.6%;
					height: 40px;
					border-top-right-radius: 40px;
					border-bottom-right-radius: 40px;

					@media screen and(min-width:320px) and (max-width:767px) {
						width: 20.8%;
					}
				}
			}
		}

		.pc-user {

			// @media screen and(min-width:320px) and (max-width:767px) {
			// 	display: none;
			// }
			.label {
				font-weight: 800;

				@media screen and(min-width:320px) and (max-width:767px) {
					display: none;
				}
			}

			.mo-label {
				color: #0058B1;
				display: none;
				font-weight: 800;

				@media screen and(min-width:320px) and (max-width:767px) {
					display: block;
					margin: 0 10px;
				}
			}

			.mine,
			.mill,
			.liner {
				font-size: 14px;
				margin-top: 20px;

				@media screen and(min-width:320px) and (max-width:767px) {
					display: none;
				}

				.item {
					display: inline-block;
					margin-right: 40px;
					cursor: pointer;
					border: 1px solid #888;
					padding: 7px 0 4px;
					border-radius: 40px;
					text-align: center;
					width: 100px;
					// transition: all .3s;

					span {
						width: 100%;
						display: inline-block;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}

					&:hover {
						background: linear-gradient(-36deg, #0064C8, #3995F0);
						border-color: #0064C8;
						color: #fff;
					}
				}

				.active {
					background: linear-gradient(-36deg, #0064C8, #3995F0);
					border-color: #0064C8;
					color: #fff;
				}
			}

			.mo-company,
			.mo-mine,
			.mo-mill,
			.mo-liner {
				display: none;

				@media screen and(min-width:320px) and (max-width:767px) {
					display: flex;
					justify-content: space-between;
					align-items: center;
					margin: 20px 10px 0;
					padding-bottom: 5px;
					border-bottom: 1px solid #888;

					.el-select {
						width: 40%;

						input {
							border: 0;
						}
					}
				}
			}

			.company {
				font-size: 14px;

				@media screen and(min-width:320px) and (max-width:767px) {
					display: none;
				}

				.item {
					display: inline-block;
					margin-right: 60px;
					cursor: pointer;
					transition: all .3s;

					&:hover {
						color: #0058B1;
					}
				}

				.active {
					color: #0058B1;
				}
			}

			.top-option {
				margin: 20px 0;

				@media screen and(min-width:320px) and (max-width:767px) {
					display: none;
				}

				.item {
					display: inline-block;
					padding: 10px 20px;
					margin-right: 15px;
					font-size: 14px;
					cursor: pointer;
					transition: all .3s;
					border-bottom: 2px solid #fff;

					&:hover {
						border-color: #3292F3;
					}
				}

				.active {
					border-color: #3292F3;
				}
			}
		}
	}
</style>
